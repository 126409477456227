<template>
  {{ search }}

  <h2>Recherche</h2>

  <Search
    v-model="search"
    returnObject
    searchEndpoint="utilisateur"
    searchPlaceholder="Rechercher un utilisateur"
  >
    <!-- Slot is for filters -->
    <SelectExtended
      id="entite"
      v-model="search.filters.entite.values"
      multiple
      search
      label="Entité"
      optionKey="id"
      optionValue="nom"
      apiEndpoint="entite"
    />

    <SelectExtended
      id="profil"
      v-model="search.filters.profil.values"
      multiple
      label="Profil"
      optionKey="id"
      optionValue="designation"
      apiEndpoint="profil"
    />

    <Checkbox
      v-model="search.filters.actif.values"
      id="actif"
      :items="[{ value: true }]"
      label="Actif"
    />
  </Search>

  <pre v-if="this.users.meta">
    <code>{{ JSON.stringify(this.users.meta.pagination, null, 2) }}</code>
    </pre>
  <pre><code>{{ JSON.stringify(this.users.data, null, 2) }}</code></pre>
</template>

<script>
import Search from '@/components/list/Search.vue'
import SelectExtended from '@/components/form/SelectExtended.vue'
import Checkbox from '@/components/form/Checkbox.vue'

export default {
  name: 'StyleguideSearchView',
  components: {
    Search,
    SelectExtended,
    Checkbox,
  },
  data() {
    return {
      search: {
        attributs: ['nom', 'prenom', 'email'],
        filters: {
          entite: {
            type: 'object',
            key: 'id',
            values: null,
          },
          profil: {
            type: 'object',
            key: 'uid',
            values: null,
          },
          actif: {
            type: 'boolean',
            key: null,
            values: null,
            label: 'Actif',
          },
        },
      },
      users: [],
      params: {},
    }
  },
  created() {
    this.getUser()
  },
  methods: {
    updateParams(searchQuery) {
      this.params.filters = searchQuery
      this.getUser()
    },
    getUser() {
      this.fetchService.get('utilisateur', this.params).then(
        (response) => {
          this.users = response
        },
        (error) => {
          console.log(error)
        },
      )
    },
  },
}
</script>
